exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-everest-trek-index-tsx": () => import("./../../../src/pages/everest-trek/index.tsx" /* webpackChunkName: "component---src-pages-everest-trek-index-tsx" */),
  "component---src-pages-experience-index-tsx": () => import("./../../../src/pages/experience/index.tsx" /* webpackChunkName: "component---src-pages-experience-index-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-index-tsx": () => import("./../../../src/pages/menu/index.tsx" /* webpackChunkName: "component---src-pages-menu-index-tsx" */)
}

